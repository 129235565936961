import "../auxillary.scss"
import React from "react"
import { FooterContent } from "../../Footer/Footer"

function TermsPolicy() {
  return (
    <>
      <section className="auxillary-wrapper">
        <div className="auxillary cookies-policy">
          <div className="auxillary__inner">
            <h1>Terms of Use</h1>
            <div className="auxillary__content-column">
              <p>Effective February 27, 2023 Notice Version 1.0</p>
              <p>
                These Terms and Conditions (hereinafter the “Terms”) are entered
                into by and between You and United Talent Agency, LLC and its
                Affiliates (“Company”, “we” or “us”). The following Terms
                together with any documents they expressly incorporate by
                reference govern your access to and use of{" "}
                <a
                  href="https://www.unitedtalent.com"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  www.unitedtalent.com
                </a>
                ,{" "}
                <a
                  href="https://www.utaspeakers.com/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  www.utaspeakers.com
                </a>
                ,{" "}
                <a
                  href="https://www.thedigitalbrandarchitects.com"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  www.thedigitalbrandarchitects.com
                </a>
                ,{" "}
                <a
                  href="https://www.mediahound.com"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  www.mediahound.com
                </a>
                ,{" "}
                <a
                  href="https://www.medialink.com"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  www.medialink.com
                </a>
                ,{" "}
                <a
                  href="https://www.siberia.io"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  www.siberia.io
                </a>
                ,{" "}
                <a
                  href="http://www.utaartistspace.com"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  www.utaartistspace.com
                </a>
                , and{" "}
                <a
                  href="https://www.uta.vc"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  www.uta.vc
                </a>
                , including any content, functionality and services offered on
                or through the Website (“Website”).
              </p>
              <p>
                PLEASE READ THESE TERMS CAREFULLY BEFORE USING THE WEBSITE AS
                THEY ARE BINDING ON YOU. BY USING THE WEBSITE, YOU ARE
                CONFIRMING THAT YOU UNDERSTAND AND AGREE TO BE BOUND BY ALL OF
                THESE TERMS. IF YOU ARE ACCEPTING THESE TERMS ON BEHALF OF A
                COMPANY OR OTHER LEGAL ENTITY, YOU REPRESENT THAT YOU HAVE THE
                LEGAL AUTHORITY TO ACCEPT THESE TERMS ON THAT ENTITY&apos;S
                BEHALF, IN WHICH CASE “YOU” WILL MEAN THAT ENTITY. IF YOU DO NOT
                AGREE TO THESE TERMS OR IF YOU DO NOT HAVE AUTHORITY TO ENTER
                INTO THESE TERMS ON BEHALF OF A COMPANY, YOU MAY NOT ACCESS OR
                USE THE WEBSITE.
              </p>

              <p>
                The Website and Company&apos;s services are offered and
                available to users who are at least 13 years of age or older. By
                using the Website, you represent and warrant that you are of
                legal age to form a binding contract with the Company and meet
                all of the foregoing eligibility requirements. If you do not
                meet all of these requirements, you must not access or use the
                Website.
              </p>

              <h2>Changes to the Terms</h2>

              <p>
                We may make changes to these Terms at any time at our sole
                discretion. Such changes may be done with or without notice. Any
                such changes will become binding on you upon your first use of
                the Website after the changes have been implemented. You are
                therefore advised to check this page from time to time.
              </p>

              <p>
                In the event of any conflict between the current version of
                these Terms and any previous versions, the provisions current
                and in effect shall prevail unless it is expressly stated
                otherwise.
              </p>

              <h2>Accessing the Website</h2>

              <p>
                Access to the Website is provided free of charge. It is your
                responsibility to make any and all arrangements necessary in
                order to access the Website. Access to the Website is provided
                “as is” and on an “as available” basis. We may alter, suspend or
                discontinue the Website, or any part of it at any time without
                notice. We will not be liable to you in any way if the Website
                or any part of it is unavailable at any time and for any period.
              </p>

              <h2>Intellectual Property Rights</h2>

              <p>
                You expressly acknowledge and agree that any and all
                Intellectual Property Rights associated with the Website and its
                Content (including, but without limitation to all art, design,
                text, graphics, user interfaces, “look and feel”, photos, audio,
                video, complication of the content, code and data, computer code
                and all other forms of information or data), as between you and
                the Company, are our property, and that of our affiliates or are
                licensed for our use, unless otherwise is expressly set forth in
                these Terms.
              </p>

              <p>
                The Content is protected by Intellectual Property Rights and
                other laws available in the United States of America and other
                countries. Elements of the Website are also protected by unfair
                competition, and other laws and may not be copied or imitated in
                whole or in part.
              </p>

              <p>
                All customized graphics, icons, and other items that appear on
                the Website are trademarks, service marks or trade name
                (&quot;Marks&quot;) of the Company, its affiliates or other
                entities that have granted us the right and license to use such
                Marks and may not be used or interfered with in any manner
                without our express written consent.
              </p>

              <p>
                Except as otherwise expressly authorized by these Terms, or
                without our prior written permission, you are not allowed to
                reproduce, copy, distribute, sell, rent, sub-license, store, or
                in any other manner reuse the Content. Except as expressly
                provided herein, we do not grant to you any express or implied
                rights to our Intellectual Property Rights or that of any third
                party.
              </p>

              <h2>Prohibited Uses</h2>

              <p>
                You acknowledge and expressly agree that you are responsible for
                your own conduct while using the Website, and for any
                consequences thereof. You agree to use the Website only for
                purposes that are legal, proper and in accordance with these
                Terms and any applicable laws or regulations. You acknowledge
                and expressly agree that you, you shall not, and shall not allow
                any third party to:
              </p>

              <p>
                (i) send, upload, distribute or disseminate any unlawful,
                defamatory, harassing, abusive, and fraudulent content; (ii)
                distribute software viruses, worms, Trojan horses, corrupted
                files, or any other malware or any other items of a destructive
                or deceptive nature; (iii) upload, post, transmit or otherwise
                make available through the Website any content that infringes
                the intellectual proprietary rights of others; (iv) use the
                Website to violate the legal rights of others; (v) engage in,
                promote, or encourage illegal activity, including, without
                limiting to, money laundering; (vi) interfere with other
                users&apos; enjoyment of the Website; (vii) exploit the Website
                for any unauthorized commercial purpose; (viii) copy, alter,
                translate, modify, create derivative works from, reproduce,
                resell, reverse assemble, reverse engineer, reverse compile any
                portion of the Website; (ix) remove any copyright, trademark or
                other proprietary rights notices contained in or on the Website;
                (x) reformat or “frame” or “mirror” any portion of the Website;
                (xi) use any robot, spider, Website search/retrieval
                application, or other device to retrieve or index any portion of
                the Website or the content posted on the thereof, or to collect
                information about its users for any unauthorized purpose; and
                (xii) access or use the Website for the purpose of creating a
                product or service that is competitive with any of our products
                or services.
              </p>

              <p>
                If you engage in any of the activities prohibited by this
                section, we may, at our sole and absolute discretion, without
                notice to you, and without limiting any of our other rights or
                remedies at law or in equity, immediately suspend your use of
                the Website.
              </p>

              <h2>Information Purposes</h2>

              <p>
                All information displayed on the Website is provided for
                informational purposes only and is not intended to provide and
                shall not constitute professional advice of any nature. Neither
                we nor our third-party providers are not responsible for any
                errors, incompleteness, inaccuracies, delays or actions taken
                depending on the information contained therein. Before making
                any decisions based on any information or other Content on the
                Website, you alone are responsible for evaluating the merits and
                risks associated with utilizing such information.
              </p>

              <h2>Changes to the Website</h2>

              <p>
                We may update the Content on this Website from time to time, but
                its Content is not necessarily complete or up-to-date. Any of
                the material on the Website may be out of date at any given
                time, and we are under no obligation to update such material.
              </p>

              <h2>Privacy Policy</h2>

              <p>
                Your access to the Website is also conditioned upon your
                acceptance of our Privacy Policy which is incorporated to these
                Terms by this reference and make up an integral part of these
                Terms. Our Privacy Policy sets out our data collection and
                processing practices and principles. We recommend that you read
                our Privacy Policy and make sure you understand them before
                accepting these Terms.
              </p>

              <h2>Linking to the Website</h2>

              <p>
                You may link to the Website (or to any page of the Website)
                provided that: (i) you do so in a fair and legal way; (ii) you
                do not do so in a way that suggests any form of association,
                endorsement, or approval on our part where none exists; (iii)
                you do not use any logos or trademarks displayed on the Website
                without our express written permission; and (iv) you do not do
                so in a way that is intended to damage our reputation or to take
                unfair advantage of it.
              </p>

              <p>
                Framing or embedding of the Website on other websites requires
                our express written permission. Please contact us at
                unitedtalent.com or further information.
              </p>

              <p>
                You may not link to the Website from any other Website where
                that Website&apos;s main content (i.e. the Website&apos;s
                primary purpose and content, not comments or similar from other
                users) contains material that:
              </p>

              <p>
                (i) is sexually explicit; (ii) is obscene, deliberately
                offensive, hateful, or otherwise inflammatory; (iii) promotes
                violence; (iv) promotes or assists in any form of unlawful
                activity; (v) discriminates against, or is in any way defamatory
                of, any person, group, or class of persons; race; gender;
                religion; nationality; disability; sexual orientation; or age;
                (vi) is intended or is otherwise likely to threaten, harass,
                annoy, alarm, inconvenience, upset, or embarrass another person;
                (vii) is calculated or is otherwise likely to deceive another
                person; (viii) is intended or is otherwise likely to infringe
                (or to threaten to infringe) another person&apos;s privacy; (ix)
                misleadingly impersonates any person or otherwise misrepresents
                the identity or affiliation of a particular person in a way that
                is calculated to deceive (obvious parodies are not included in
                this definition); (x) implies any form of affiliation with us
                where none exists; (xi) infringes, or assists in the
                infringement of, the intellectual property rights (including,
                but not limited to, copyright, trademarks, patents, and database
                rights) of any other party; or (xii) is made in breach of any
                legal duty owed to a third party including, but not limited to,
                contractual duties and duties of confidence.
              </p>

              <h2>Links from the Website</h2>

              <p>
                The Website may contain links to third-party websites or
                services that are not owned or controlled by us. We have no
                control over or assume any responsibility for the content,
                privacy policies, or practices of any third-party websites or
                services. You further acknowledge and agree that we shall not be
                responsible or liable, directly or indirectly, for any damage or
                loss caused or alleged to be caused by or in connection with the
                use of or reliance on any such content or services available on
                or through any such websites or services. We strongly recommend
                that you read the terms and conditions and privacy policies of
                any third-party websites or services that you visit.
              </p>

              <h2>Disclaimer of Warranties</h2>

              <p>
                The Website is provided on an “as is” and “as available” basis
                without any representation or warranty, whether express or
                implied, to the maximum extent permitted by applicable law:
                specifically, we disclaim any implied warranties of title,
                merchantability, fitness for a particular purpose and/or
                non-infringement. We do not make any representations or
                warranties that access to the Website, or any of the materials
                contained therein, will be continuous, uninterrupted, timely, or
                error-free.
              </p>

              <h2>Limitation on Liability</h2>

              <p>
                To the fullest extent permissible by law, we accept no liability
                to any user of the Website for any loss or damage, whether
                foreseeable or otherwise, in contract, tort (including
                negligence), for breach of statutory duty, or otherwise, arising
                out of or in connection with the use of (or inability to use)
                the Website or the use of or reliance upon any Content included
                on the Website.
              </p>

              <p>
                To the fullest extent permissible by law, we exclude all
                representations, warranties, and guarantees, whether express or
                implied, that may apply to the Website, or any Content included
                on the Website. We accept no liability for loss of profits,
                sales, business or revenue; loss of business opportunity,
                goodwill or reputation; loss of anticipated savings; business
                interruption; or for any indirect or consequential loss or
                damage.
              </p>

              <p>
                We exercise all reasonable skill and care to ensure that the
                Website is free from viruses and other malware. However, we
                accept no liability for any loss or damage resulting from a
                virus or other malware, a distributed denial of service attack,
                or other harmful material or event that may adversely affect
                your hardware, software, data or other material that occurs as a
                result of your use of the Website, or any other Website referred
                to on the Website.
              </p>

              <p>
                We neither assume nor accept responsibility or liability arising
                out of any disruption or non-availability of the Website
                resulting from external causes including, but not limited to,
                ISP equipment failure, host equipment failure, communications
                network failure, natural events, acts of war, or legal
                restrictions and censorship.
              </p>

              <p>
                Nothing in these Terms excludes or restricts our liability for
                fraud or fraudulent misrepresentation, for death or personal
                injury resulting from negligence, or for any other forms of
                liability which cannot be excluded or restricted by law.
              </p>

              <h2>Indemnification</h2>

              <p>
                You agree to indemnify and hold us and any of our founders,
                officers, employees, and agents harmless, including costs and
                attorneys&apos; fees, from any claim or demand made by any third
                party due to or arising out of (i) your use of the Website, (ii)
                your violation of these Terms, or (iii) your violation of
                applicable laws or regulations. We retain the right, at your
                expense, to assume the exclusive defense and control of any
                matter for which you are required to indemnify us, and you agree
                to cooperate with our defense of these claims. You agree not to
                settle any matter without our prior written consent. We will use
                reasonable efforts to notify you of any such claim, action or
                proceeding upon becoming aware of it.
              </p>

              <h2>User Submissions</h2>

              <p>
                You may choose to submit comments, bug reports, ideas or other
                feedback about the Website, including without limitation about
                how to improve the Website (collectively, “Feedback”). By
                sending any Feedback, you agree that we are free to use and
                distribute such Feedback to third parties at our discretion and
                without any compensation to you, whether on a non-confidential
                basis or otherwise. You hereby grant us a perpetual,
                irrevocable, non-exclusive, worldwide license under all rights
                necessary for us to incorporate and use your Feedback for any
                purpose.
              </p>

              <h2>Reliance on Third Parties</h2>

              <p>
                In order to deliver the Website to you we rely on certain
                third-party service providers, including but not limited to our
                web hosting service provider. We have no control on these
                service providers. Any system failure, technical error or any
                other negative event may affect the delivery of the Website to
                you. We shall not be liable or responsible for any negative
                events that hinder your enjoyment of our Website due to fault or
                negligence of our service providers.
              </p>

              <h2>Disputes; Mandatory Arbitration and Class Action Waiver</h2>

              <p>
                These Terms, any access to or use of the Website will be
                governed by the laws of the California, U.S.A., excluding its
                conflict of law provisions, and the proper venue for any
                disputes arising out of or relating to any of the same will be
                the state and federal courts located in Los Angeles County,
                California.
              </p>

              <p>
                Except for claims for injunctive or equitable relief or claims
                regarding intellectual property rights (which may be brought in
                any competent court without the posting of a bond), any dispute
                arising under this Agreement shall be finally settled in
                accordance with the Comprehensive Arbitration Rules of the
                Judicial Arbitration and Mediation Service, Inc. (“JAMS”) by
                three arbitrators appointed in accordance with such Rules. The
                arbitration shall take place in Los Angeles, California, in the
                English language and the arbitral decision may be enforced in
                any court. The prevailing party in any action or proceeding to
                enforce this Agreement shall be entitled to costs and
                attorneys&apos; fees.
              </p>

              <p>
                Any cause of action or claim you may have relating to these
                Terms must be commenced within 1 year after the cause of action
                accrues; Otherwise, such cause of action or claim is permanently
                barred.
              </p>

              <p>
                The statute of limitations and any filing fee deadlines shall be
                tolled while the parties engage in the informal dispute
                resolution process required by this section.
              </p>

              <p>
                You expressly give up your right to participate in a class
                action or other class proceeding.
              </p>

              <p>
                Nothing in this clause affects a party&apos;s right to obtain
                interlocutory relief or to commence legal proceedings.
              </p>

              <p>Miscellaneous</p>

              <p>
                No waiver by us of any term or condition set out in these Terms
                shall be deemed a further or continuing waiver of such term or
                condition or a waiver of any other term or condition, and any
                failure of ours to assert a right or provision under these Terms
                shall not constitute a waiver of such right or provision.
              </p>

              <p>
                The provisions of these Terms are independent of one another. In
                case any provision of the Terms is found by a competent court or
                authority to be invalid, illegal or unenforceable, the validity,
                legality and enforceability of the remaining provisions shall
                not in any way be affected or impaired thereby and such
                provision shall be ineffective only to the extent of such
                invalidity, illegality or unenforceability.
              </p>

              <p>
                These Terms, including any rules or policies incorporated by
                reference into them, are the whole agreement between you and us
                concerning the Website. We exercise all reasonable skill and
                care to ensure that our Website is secure and free from viruses
                and other malware. Please note, it is your responsibility to
                protect your hardware, software, data, and other material from
                viruses, malware, and other Internet security risks.
              </p>

              <p>
                It is strictly prohibited to use or contact the Website to
                disrupt or damage the Website, its contents or its security
                measures or to harass or disparage the Company, its affiliates,
                or its clients or their respective products, services or
                personnel. No unsolicited email (spam) may be directed to or
                through the Website.
              </p>

              <p>
                The Company operates exclusively by referral and does not accept
                unsolicited material or solicitations of any kind. Any such
                materials received will be returned or destroyed at the
                agency&apos;s discretion.
              </p>

              <h2>Comments and Concerns</h2>

              <p>
                This website is operated by United Talent Agency, LLC: If you
                have questions, feedback, or requests about our Terms, please
                use the following contact information:
                <a href="mailto:privacy@unitedtalent.com">
                  Privacy@unitedtalent.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="auxillary-footer">
        <FooterContent selected />
      </section>
    </>
  )
}

export default TermsPolicy
